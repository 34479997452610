@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'AfacadFlux';
    src: url('../public/AfacadFlux-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'AfacadFlux-Light';
    src: url('../public/AfacadFlux-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'AfacadFlux-ExtraLight';
    src: url('../public/AfacadFlux-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
  font-family: 'EBGaramond';
  src: url('../public/EBGaramond-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}